import React from "react";
import "./Portada.scss";
import { Row } from "react-bootstrap";

const Portada = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  return (
    <Row className="row_portada">
      <img
        src={
          isMobileDevice
            ? "https://i.imgur.com/V8yR2fn.jpg"
            : "https://i.imgur.com/0PuEKhc.png"
        }
        alt=""
        className="img_portada"
      />
      <h1 className="h1_prensa">{"Prensa".toUpperCase()}</h1>
    </Row>
  );
};

export default Portada;
