export const noticias = [
  {
    id: 1,
    title:
      "Interactividad y realidad aumentada: así se planifica el futuro de la industria inmobiliaria en el Chaco",
    desc: "En esta nueva manera de ofrecer al cliente un inmueble fue que surgió la necesidad de crear una plataforma casi tangible, para ofrecer la mejor experiencia a la hora de adquirir un nuevo inmueble",
    img: "https://i.imgur.com/c16qzFI.png",
  },
  {
    id: 2,
    title:
      "Se emplazó la escultura N°. 649 y con ella se incorporó la figura de “Guardianes del Patrimonio”",
    desc: "Se emplazó la escultura 649 de Resistencia, en el boulevard Sarmiento del 200 al 300. Se trata de la obra de autor desconocido titulada “Cabeza de Tornado”; realizada en mármol travertino y fue donada por la desarrollista inmobiliaria BASA.",
    img: "https://i.imgur.com/x9geuEZ.png",
    slideImg: [
      {
        original: "https://i.imgur.com/E5mrq6P.jpg",
        thumbnail: "https://i.imgur.com/E5mrq6Pt.jpg", //
      },
      {
        original: "https://i.imgur.com/qX4jmq9.jpg",
        thumbnail: "https://i.imgur.com/qX4jmq9t.jpg", //
      },
      {
        original: "https://i.imgur.com/VKbqovy.jpg",
        thumbnail: "https://i.imgur.com/VKbqovyt.jpg", //
      },
      {
        original: "https://i.imgur.com/ny0ZDdd.jpg",
        thumbnail: "https://i.imgur.com/ny0ZDddt.jpg", //
      },
      {
        original: "https://i.imgur.com/pfljqRt.jpg",
        thumbnail: "https://i.imgur.com/pfljqRtt.jpg", //
      },
    ],
  },
  {
    id: 3,
    title:
      "RESISTENCIA: Basa Se Transforma En Guardianes Del Patrimonio Con La Escultura 649",
    desc: "Se trata de la obra de autor desconocido titulada ‘Cabeza de Tornado’, realizada en mármol travertino y fue donada por la desarrollista inmobiliaria BASA que se suma además de manera activa a la conservación del patrimonio escultórico de nuestra ciudad, transformándose en los primeros “Guardianes’.",
    img: "https://i.imgur.com/zrTFfgB.png",
  },
  {
    id: 4,
    title:
      "Boulevard Sarmiento: BASA suma un desarrollo inmobiliario residencial de alta gama en el corazón de Resistencia",
    desc: "A pocos días de su inauguración, la empresa BASA destaca al nuevo edificio de departamentos residenciales Boulevard Sarmiento como un aporte arquitectónico urbano y paisajístico a 150 metros de la Plaza 25 de Mayo de la capital chaqueña.",
    img: "https://i.imgur.com/azfulvM.png",
  },
  {
    id: 5,
    title:
      "Cuáles son los sectores que se perfilan con mejores oportunidades por efecto del cepo cambiario",
    desc: "El último cepo cambiario que se implementó para contener la sangría de las reservas internacionales, también ofrecería nuevas oportunidades a algunos sectores puntuales de la economía, vinculados a los consumos de clase media.",
    img: "https://i.imgur.com/pbIoBEj.png",
    href: "https://www.clarin.com/economia/economia/sectores-perfilan-mejores-oportunidades-efecto-cepo-cambiario_0_nSupAQtI.html",
  },
  {
    id: 6,
    title: "El sector se prepara para una nueva etapa",
    desc: "La incógnita se develó. Alberto Fernández será el nuevo presidente de los argentinos y todo indicaría que arranca una nueva etapa para el mercado inmobiliario con otras reglas, cepo, y una mayor intervención del Estado en la política habitacional del país.",
    img: "https://i.imgur.com/jGWJbVC.png",
    href: "https://www.lanacion.com.ar/propiedades/el-sector-se-prepara-nueva-etapa-nid2302926/",
  },
  {
    id: 7,
    title:
      "En qué se pueden invertir los pesos en tiempos de restricciones con el dólar",
    desc: "Dólar bolsa, compra de propiedades desde el pozo e inversiones en el mercado de capitales son algunas de las opciones. Pero es necesario contar con un asesoramiento que tenga en cuenta cuáles son las necesidades del inversor",
    img: "https://i.imgur.com/RlaWEcm.png",
    href: "https://www.infobae.com/economia/finanzas-y-negocios/2019/10/31/en-que-se-pueden-invertir-los-pesos-en-tiempos-de-restricciones-con-el-dolar/",
  },
  {
    id: 8,
    title: "División Hotelera",
    desc: "Nos reunimos con el Gobernador electo Jorge Milton Capitanich y directivos de Amérian Hoteles, socio estratégico de nuestra división hotelera, para analizar una serie de proyectos que tienen como objetivo fortalecer y ampliar la capacidad y calidad hotelera de la provincia. Conoce mas ingresando AQUÍ",
    img: "https://i.imgur.com/UZQScEE.png",
  },
];
