import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../../../assets/logo.svg";

const Footer = () => {
  var today = new Date();
  var year = today.getFullYear();
  return (
    <section className="sec-footer">
      <Row className="p-5 justify-content-md-center">
        <Col md={6} className="col-img-footer">
          <div className="div_img-footer">
            <img src={logo} alt="" className="img-footer" width={"100%"} />
          </div>
        </Col>
        <Col md={6} className="col-footer-info">
          <b>Dirección:</b>
          <p>
            Pje. Félix Codutti 836 (Alt. Av. Hernandarias 450) Resistencia Chaco
          </p>
          <b>Email:</b>
          <p>
            <a
              href="mailto:info@basadesarrollos.com"
              className="text-white"
              target={"_blank"}
              rel="noreferrer"
            >
              info@basadesarrollos.com
            </a>
          </p>
          <b>Celular:</b>
          <p>
            <a
              href="https://web.whatsapp.com/send?phone=5493624920002&text="
              className="text-white"
              target={"_blank"}
              rel="noreferrer"
            >
              +54-3624-920002
            </a>
          </p>
          <b>Social:</b>
          <p>
            <a
              href="https://www.facebook.com/BasaDesarrollos"
              className="fb-icon ficon"
              target={"_blank"}
              rel="noreferrer"
            >
              <FacebookIcon className="text-white" fontSize="medium" />
            </a>
            <a
              href="https://instagram.com/basadesarrollos?igshid=YmMyMTA2M2Y="
              className="ig-icon ficon"
              target={"_blank"}
              rel="noreferrer"
            >
              <InstagramIcon className="text-white" fontSize="medium" />
            </a>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4} className="text-center">
          <p className="derechos">
            &copy; BASA Desarrollos {year} | Todos los derechos reservados
          </p>
        </Col>
      </Row>
    </section>
  );
};

export default Footer;
