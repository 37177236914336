export const desarrollos = [
  {
    title: "Torres Magistrados",
    state: "",
    category: "residenciales",
    category_title: "Residenciales",
    direccion: "Entre Ríos 545 - Corrientes 546",
    desc: "",
    broschure: "",
    planos: "",
    img: "https://i.imgur.com/E8J3lam.jpg",
    img_mobile: "https://i.imgur.com/E8J3lam.jpg",
    img_ext: "https://i.imgur.com/E8J3lam.jpg",
    img_arr: [
      // {
      //   original: "https://i.imgur.com/RNkw7AX.jpg",
      //   thumbnail: "https://i.imgur.com/RNkw7AXt.jpg", //
      // },
    ],
    ubication:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.8154869372274!2d-58.990584589812656!3d-27.443860515505495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450c5e38768f8b%3A0x69d13c38201889a4!2sEntre%20R%C3%ADos%20545%2C%20H3500AIL%20Resistencia%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1683472375220!5m2!1ses-419!2sar",
  },
  {
    title: "P903",
    state: "Pre-Venta",
    category: "urbanos",
    category_title: "Urbanos",
    direccion: "J. D. Perón 903",
    desc: "Su ubicación estratégica nos permite disfrutar de la tranquilidad del barrio con acceso directo a la zona céntrica y corredores urbanos. Cercanía a colegios, clubes y una variada zona comercial en crecimiento constante.",
    broschure:
      "https://drive.google.com/uc?id=1N1FiIATk57k2avRoiGjKb1ZPWZ1bDwR7&export=download&authuser=0",
    planos: "",
    img: "https://i.imgur.com/eyYiu5O.png",
    img_mobile: "https://i.imgur.com/xrEc9dI.jpg",
    img_ext: "",
    imgArr_portada: [
      "https://i.imgur.com/Yms3HNq.jpg",
      "https://i.imgur.com/aERHTlQ.jpg",
      "https://i.imgur.com/QgRXcm1.jpg",
      "https://i.imgur.com/eLjVP16.jpg",
    ],
    imgArr_portadaMobile: [
      "https://i.imgur.com/RKceaLL.jpg",
      "https://i.imgur.com/cKj7y9i.jpg",
      "https://i.imgur.com/R8wY3XV.jpg",
      "https://i.imgur.com/ARSolJH.jpg",
    ],
    img_arr: [
      {
        original: "https://i.imgur.com/VqVcIst.jpg",
        thumbnail: "https://i.imgur.com/VqVcIstt.jpg", //
      },
      {
        original: "https://i.imgur.com/sVK1SZv.jpg",
        thumbnail: "https://i.imgur.com/sVK1SZvt.jpg", //
      },
      {
        original: "https://i.imgur.com/3sWBGn5.jpg",
        thumbnail: "https://i.imgur.com/3sWBGn5t.jpg", //
      },
      {
        original: "https://i.imgur.com/JJgAQyf.jpg",
        thumbnail: "https://i.imgur.com/JJgAQyft.jpg", //
      },
      {
        original: "https://i.imgur.com/RtuBQGC.jpg",
        thumbnail: "https://i.imgur.com/RtuBQGCt.jpg", //
      },
    ],
    img_arr_mobile: [
      {
        original: "https://i.imgur.com/Wu9AvFW.jpg",
        thumbnail: "https://i.imgur.com/Wu9AvFWt.jpg", //
      },
      {
        original: "https://i.imgur.com/tHWUfJC.jpg",
        thumbnail: "https://i.imgur.com/tHWUfJCt.jpg", //
      },
      {
        original: "https://i.imgur.com/DovHiAu.jpg",
        thumbnail: "https://i.imgur.com/DovHiAut.jpg", //
      },
      {
        original: "https://i.imgur.com/9U3xoWS.jpg",
        thumbnail: "https://i.imgur.com/9U3xoWSt.jpg", //
      },
      {
        original: "https://i.imgur.com/9oMiqK8.jpg",
        thumbnail: "https://i.imgur.com/9oMiqK8t.jpg", //
      },
    ],
    vr_link:
      "https://kuula.co/share/collection/7YS3d?logo=-1&info=1&fs=1&vr=0&sd=1&thumbs=1&chromeless=1&inst=es&keys=0&initload=0&enablejs=1&fid=0f9&priority=1",
    ubication:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.759517363942!2d-58.99910678981249!3d-27.445604415579744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450c6394ef3063%3A0xfa8ca4972ce6c3e4!2sJuan%20Domingo%20Per%C3%B3n%20903%2C%20H3506KJX%20Resistencia%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1681787535731!5m2!1ses-419!2sar",
  },
  {
    title: "A344",
    state: "Pronta entrega",
    category: "urbanos",
    category_title: "Urbanos",
    direccion: "Ameghino 344",
    desc: "A344 cuenta con una ubicación estratégica en el centro de la ciudad Calle Ameghino 344, con un entorno seguro, excelente accesibilidad, todos los servicios, medios de transporte, a corta distancia del Campus UNNE y a pasos de un pulmón verde totalmente renovado como es la Plaza España.",
    broschure:
      "https://drive.google.com/uc?id=15LHxMRqp7yJWBwGKNWNNyzsF8nC4co2q&export=download&authuser=0",
    planos: "",
    img: "https://i.imgur.com/Qj1lkh6.png",
    img_mobile: "https://i.imgur.com/KfhsxyD.jpg",
    img_ext: "https://i.imgur.com/JKDyytd.jpg",
    img_ext_mobile: "https://i.imgur.com/4ObsTA2.jpg",
    img_arr: [
      {
        original: "https://i.imgur.com/RNkw7AX.jpg",
        thumbnail: "https://i.imgur.com/RNkw7AXt.jpg", //
      },
      {
        original: "https://i.imgur.com/UOs1Gi3.jpg",
        thumbnail: "https://i.imgur.com/UOs1Gi3t.jpg", //
      },
      {
        original: "https://i.imgur.com/l892ivM.jpg",
        thumbnail: "https://i.imgur.com/l892ivMt.jpg", //
      },
      {
        original: "https://i.imgur.com/VoClr8c.jpg",
        thumbnail: "https://i.imgur.com/VoClr8ct.jpg", //
      },
      {
        original: "https://i.imgur.com/D0decgt.jpg",
        thumbnail: "https://i.imgur.com/D0decgtt.jpg", //
      },
    ],
    img_arr_mobile: [
      {
        original: "https://i.imgur.com/bb3QSYI.jpg",
        thumbnail: "https://i.imgur.com/bb3QSYIt.jpg", //
      },
      {
        original: "https://i.imgur.com/I3JTjlC.jpg",
        thumbnail: "https://i.imgur.com/I3JTjlCt.jpg", //
      },
      {
        original: "https://i.imgur.com/RxD9kEL.jpg",
        thumbnail: "https://i.imgur.com/RxD9kELt.jpg", //
      },
      {
        original: "https://i.imgur.com/4ZyWTob.jpg",
        thumbnail: "https://i.imgur.com/4ZyWTobt.jpg", //
      },
      {
        original: "https://i.imgur.com/6LhTqLv.jpg",
        thumbnail: "https://i.imgur.com/6LhTqLvt.jpg", //
      },
    ],
    ubication:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d885.0977888597542!2d-58.987746242366526!3d-27.45707928846922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450cf285947b27%3A0x352622cb5587edff!2sAmeghino%20344%2C%20H3500AZH%20Resistencia%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1681786605337!5m2!1ses-419!2sar",
  },
  {
    title: "SF745",
    state: "En obra",
    category: "altura",
    category_title: "Casas en altura",
    direccion: "Santa Fe 745",
    desc: "Un concepto único e innovador que combina la calidez y confort del hogar con la seguridad y prestaciones de un edificio de alta gama. Propone un ensamble perfecto entre diseño, calidad y eficiencia con la nobleza de materiales como la madera, ladrillo y hormigón. Mucho más que un departamento, es una nueva forma de vivir, cerca de todo.",
    broschure:
      "https://drive.google.com/uc?id=1VcaNzffaenxlUT0F7s6gx6nV_oee7ZeH&export=download&authuser=0",
    planos: "",
    img: "https://i.imgur.com/vh32YJ4.png",
    img_mobile: "https://i.imgur.com/m0DhtG0.jpg",
    img_ext: "https://i.imgur.com/vh32YJ4.png",
    imgArr_portada: [
      "https://i.imgur.com/uKGyAnC.jpg",
      "https://i.imgur.com/Jj9EK91.jpg",
      "https://i.imgur.com/KN4e83Y.jpg",
    ],
    imgArr_portadaMobile: [
      "https://i.imgur.com/qdSenHt.jpg",
      "https://i.imgur.com/cWUoPAE.jpg",
      "https://i.imgur.com/zmEpWqW.jpg",
    ],
    img_arr: [
      {
        original: "https://i.imgur.com/NwnLxqE.jpg",
        thumbnail: "https://i.imgur.com/NwnLxqEt.jpg", //
      },
      {
        original: "https://i.imgur.com/kJOHd1x.jpg",
        thumbnail: "https://i.imgur.com/kJOHd1xt.jpg", //
      },
      {
        original: "https://i.imgur.com/ZItDlCC.jpg",
        thumbnail: "https://i.imgur.com/ZItDlCCt.jpg", //
      },
      {
        original: "https://i.imgur.com/wlc34ei.jpg",
        thumbnail: "https://i.imgur.com/wlc34eit.jpg", //
      },
    ],
    img_arr_mobile: [
      {
        original: "https://i.imgur.com/fNfH870.jpg",
        thumbnail: "https://i.imgur.com/fNfH870t.jpg", //
      },
      {
        original: "https://i.imgur.com/MuvkKza.jpg",
        thumbnail: "https://i.imgur.com/MuvkKzat.jpg", //
      },
      {
        original: "https://i.imgur.com/jOEmtgj.jpg",
        thumbnail: "https://i.imgur.com/jOEmtgjt.jpg", //
      },
      {
        original: "https://i.imgur.com/eMwg4RH.jpg",
        thumbnail: "https://i.imgur.com/eMwg4RHt.jpg", //
      },
    ],
    img_arr_2: [
      {
        original: "https://i.imgur.com/WqJn9Cj.jpg",
        thumbnail: "https://i.imgur.com/WqJn9Cjt.jpg", //
      },
      {
        original: "https://i.imgur.com/RqJWdHf.jpg",
        thumbnail: "https://i.imgur.com/RqJWdHft.jpg", //
      },
      {
        original: "https://i.imgur.com/8tW542l.jpg",
        thumbnail: "https://i.imgur.com/8tW542lt.jpg", //
      },
      {
        original: "https://i.imgur.com/VyRijUA.jpg",
        thumbnail: "https://i.imgur.com/VyRijUAt.jpg", //
      },
    ],
    img_arr_2_mobile: [
      {
        original: "https://i.imgur.com/3jGWlXM.jpg",
        thumbnail: "https://i.imgur.com/3jGWlXMt.jpg", //
      },
      {
        original: "https://i.imgur.com/tOzBHAy.jpg",
        thumbnail: "https://i.imgur.com/tOzBHAyt.jpg", //
      },
      {
        original: "https://i.imgur.com/ROE7Y72.jpg",
        thumbnail: "https://i.imgur.com/ROE7Y72t.jpg", //
      },
      {
        original: "https://i.imgur.com/d0h54cz.jpg",
        thumbnail: "https://i.imgur.com/d0h54cz.jpg", //
      },
    ],
    ubication:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.8146958967177!2d-58.99602856252218!3d-27.443885163459306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450c60c371e5fb%3A0x6e443ad8c4cc3273!2sSta.%20Fe%20745%2C%20H3500AEP%20Resistencia%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1681788142819!5m2!1ses-419!2sar",
  },
  {
    title: "BS239",
    state: "Terminado",
    category: "residenciales",
    category_title: "Residenciales",
    direccion: "Av. Sarmiento 239",
    desc: "Un concepto sofisticado en términos de espacialidad, terminaciones, amenities y equipamientos en una de las ubicaciones más exclusivas de la ciudad, Av. Sarmiento 239. Con plantas de 1 a 3 dormitorios, dependencias de servicio y balcones aterrazados. Amenities de categoría con gimnasio, área de niños, SUM con parrillas, piscina y solárium.",
    broschure: "",
    planos: "",
    img: "https://i.imgur.com/gLRfMGr.png",
    img_mobile: "https://i.imgur.com/ULQSFgF.jpg",
    img_ext_mobile: "https://i.imgur.com/wQYzGRg.jpg",
    imgArr_portada: [
      "https://i.imgur.com/Vh5cTX8.jpg",
      "https://i.imgur.com/wwJFsgy.jpg",
    ],
    img_arr: [
      {
        original: "https://i.imgur.com/5tozB6d.jpg",
        thumbnail: "https://i.imgur.com/5tozB6dt.jpg", //
      },
      {
        original: "https://i.imgur.com/AyGcLG3.jpg",
        thumbnail: "https://i.imgur.com/AyGcLG3t.jpg", //
      },
      {
        original: "https://i.imgur.com/PK25ib9.jpg",
        thumbnail: "https://i.imgur.com/PK25ib9t.jpg", //
      },
      {
        original: "https://i.imgur.com/GO5GMHf.jpg",
        thumbnail: "https://i.imgur.com/GO5GMHft.jpg", //
      },
    ],
    img_arr_mobile: [
      {
        original: "https://i.imgur.com/t9GdW5z.jpg",
        thumbnail: "https://i.imgur.com/t9GdW5zt.jpg", //
      },
      {
        original: "https://i.imgur.com/juXqmSR.jpg",
        thumbnail: "https://i.imgur.com/juXqmSRt.jpg", //
      },
      {
        original: "https://i.imgur.com/yIvne9t.jpg",
        thumbnail: "https://i.imgur.com/yIvne9tt.jpg", //
      },
      {
        original: "https://i.imgur.com/vQPjQ6B.jpg",
        thumbnail: "https://i.imgur.com/vQPjQ6Bt.jpg", //
      },
    ],
    img_arr_2: [
      {
        original: "https://i.imgur.com/VW1rvRk.jpg",
        thumbnail: "https://i.imgur.com/VW1rvRkt.jpg", //
      },
      {
        original: "https://i.imgur.com/rNXF9AC.jpg",
        thumbnail: "https://i.imgur.com/rNXF9ACt.jpg", //
      },
      {
        original: "https://i.imgur.com/e4o93ni.jpg",
        thumbnail: "https://i.imgur.com/e4o93nit.jpg", //
      },
    ],
    img_arr_2_mobile: [
      {
        original: "https://i.imgur.com/oudaTlq.jpg",
        thumbnail: "https://i.imgur.com/oudaTlqt.jpg", //
      },
      {
        original: "https://i.imgur.com/BHtYqjQ.jpg",
        thumbnail: "https://i.imgur.com/BHtYqjQt.jpg", //
      },
      {
        original: "https://i.imgur.com/ZwH8FbD.jpg",
        thumbnail: "https://i.imgur.com/ZwH8FbDt.jpg", //
      },
    ],
    ubication:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.6441112881794!2d-58.98703148981233!3d-27.449199915732784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450cf43287d21d%3A0x60f42a171b995ae3!2sAv.%20Sarmiento%20239%2C%20H3500BJP%20Resistencia%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1681786664558!5m2!1ses-419!2sar",
  },
  {
    title: "AB315",
    state: "Terminado",
    category: "urbanos",
    category_title: "Urbanos",
    direccion: "Arbo y Blanco 315",
    desc: "Dada su ubicación, la torre AyB es una excelente oportunidad para invertir. Cuenta con unidades de 35 a 100 m2, equipados con artefactos de primera calidad, y mobiliario a medida. Una terraza con vista 360 de la ciudad de Resistencia, equipada con parrillas y quincho para compartir buenos momentos.",
    broschure: "",
    planos: "",
    img: "https://i.imgur.com/iG1xUCH.png",
    img_mobile: "https://i.imgur.com/qcgDlfP.jpg",
    img_ext: "https://i.imgur.com/iG1xUCH.png",
    img_ext_mobile: "https://i.imgur.com/CtVwsrH.jpg",
    img_arr: [
      {
        original: "https://i.imgur.com/6fN63HK.png",
        thumbnail: "https://i.imgur.com/6fN63HKt.png", //
      },
      {
        original: "https://i.imgur.com/BIfLokA.png",
        thumbnail: "https://i.imgur.com/BIfLokAt.png", //
      },
      {
        original: "https://i.imgur.com/SQBb4fb.png",
        thumbnail: "https://i.imgur.com/SQBb4fbt.png", //
      },
      {
        original: "https://i.imgur.com/FpMwYzF.png",
        thumbnail: "https://i.imgur.com/FpMwYzFt.png", //
      },
    ],
    img_arr_mobile: [
      {
        original: "https://i.imgur.com/63j3WYJ.jpg",
        thumbnail: "https://i.imgur.com/63j3WYJt.jpg", //
      },
      {
        original: "https://i.imgur.com/l6BUTgq.jpg",
        thumbnail: "https://i.imgur.com/l6BUTgqt.jpg", //
      },
      {
        original: "https://i.imgur.com/cb0kS2H.jpg",
        thumbnail: "https://i.imgur.com/cb0kS2Ht.jpg", //
      },
      {
        original: "https://i.imgur.com/ALJiGqe.jpg",
        thumbnail: "https://i.imgur.com/ALJiGqet.jpg", //
      },
    ],
    ubication:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.3483506838593!2d-58.98654888981192!3d-27.458412416125004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450ceddef31e51%3A0xc82478a11122bcd2!2sArbo%20y%20Blanco%20315%2C%20H3500CKG%20Resistencia%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1681788611866!5m2!1ses-419!2sar",
  },
];
