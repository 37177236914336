// Libraries
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo.svg";

// Components

// Styles & Icons
import HomeIcon from "@mui/icons-material/Home";
import "./Header.scss";

// Nav Items
const sidebarNavItems = [
  {
    display: "Home",
    to: "/",
    section: "home",
  },
  {
    display: "Desarrollos",
    to: "/desarrollos/todos",
    section: "desarrollos",
  },
  {
    display: "Constructora",
    to: "/constructora",
    section: "constructora",
  },
  {
    display: "Prensa",
    to: "/prensa",
    section: "prensa",
  },
];

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepWidth, setStepWidth] = useState(0);
  const navRef = useRef();
  const indicatorRef = useRef();
  const location = useLocation();

  useEffect(() => {
    // const navItem = navRef.current.querySelector(".nav-item-p");
    switch (activeIndex) {
      case 0:
        setStepWidth(46);
        break;

      case 1:
        setStepWidth(50);
        break;

      case 2:
        setStepWidth(77);
        break;

      case 3:
        setStepWidth(91);
        break;

      default:
        break;
    }
    if (activeIndex === 0) {
    }
  }, [activeIndex]);

  //cambiar index activado
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="#014751"
        variant="dark"
        sticky="top"
        className="nav_header"
      >
        <Container fluid className="header-container">
          <Navbar.Brand>
            <Link to="/">
              <img
                src={logo}
                className="brand-img d-inline-block align-top"
                alt="Basa Logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav ref={navRef} className="nav_contenedor">
              <div
                ref={indicatorRef}
                id="marker-header"
                style={{
                  transform: `translateX(${
                    activeIndex * stepWidth
                  }px) translateY(50%)`,
                }}
              ></div>

              {sidebarNavItems.map((item, index) => (
                <Nav.Link
                  className={`nav-item nav-item-p ${index === 0 && "__home"}`}
                  to="/"
                  key={item.section}
                >
                  <Link
                    to={item.to}
                    key={index}
                    className={`_link-nav ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    {index === 0 ? (
                      <HomeIcon sx={{ fontSize: 25 }} />
                    ) : (
                      item.display
                    )}
                  </Link>
                </Nav.Link>
              ))}
              <Nav.Link
                className="nav-item nav-item-p contacto-p"
                href="#contacto"
              >
                <i>Contacto</i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
