import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Ubicacion from "../../Home/Ubicacion/Ubicacion";
import Contact from "../../Layouts/Contact/Contact";
import ElementoDesarrollo from "../../Layouts/ElementoDesarrollo/ElementoDesarrollo";
import { desarrollos } from "../../utils/proyectos";
import "./Despliegue.scss";

const Despliegue = () => {
  const params = useParams();
  const category = params.category;
  const catRegex = /todos|urbanos|residenciales|altura|tierra/i;
  const isCat = catRegex.test(category);

  if (category === "todos") {
    var filtrado = desarrollos;
  } else {
    // eslint-disable-next-line no-redeclare
    var filtrado = desarrollos.filter((i) => i.category === category);
  }

  useEffect(() => {
    return () => {
      if (category === "todos") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        filtrado = desarrollos;
      } else {
        filtrado = desarrollos.filter((i) => i.category === category);
      }
    };
  }, [category]);

  return (
    <>
      <Row className="row_despliegue">
        {filtrado.map((el, i) => (
          <Col xs={12} md={4} key={el.title}>
            <ElementoDesarrollo {...el} category={el.category_title} />
          </Col>
        ))}
      </Row>
      <Contact />
      {isCat && <Ubicacion />}
    </>
  );
};

export default Despliegue;
