import React from "react";
import Portada from "../Portada/Portada";
import { useParams } from "react-router-dom";
import { noticias } from "../../utils/noticias";
import "./Noticia.scss";
import { Col, Container, Row } from "react-bootstrap";
import CarouselComp from "../../Layouts/CarouselComp/CarouselComp";
import Contact from "../../Layouts/Contact/Contact";
import Ubicacion from "../../Home/Ubicacion/Ubicacion";

const Noticia = () => {
  const params = useParams();
  const idNoticia = params.idNoticia;
  const itemNoticia = noticias.find((i) => i.id === parseInt(idNoticia, 10));

  if (!itemNoticia) {
    return <div>No se encontró la noticia.</div>;
  }

  return (
    <>
      <div className="sec-noticia">
        <Portada />
        <Container className="notice">
          <h1 className="notice__title">{itemNoticia.title.toUpperCase()}</h1>

          {idNoticia === "1" && (
            <div className="notice__content">
              <div className="parrafo">
                <p>
                  Así como muchas profesiones y oficios se debieron reinventar
                  en esta nueva normalidad pandémica, la industria del inmueble
                  no fue la excepción.
                </p>
                <p>
                  En esta nueva manera de ofrecer al cliente un inmueble fue que
                  surgió la necesidad de crear una plataforma casi tangible,
                  para ofrecer la mejor experiencia a la hora de adquirir un
                  nuevo inmueble. Antes de empezar es importante distinguir este
                  dos tipos de realidades; Realidad Virtual y Realidad
                  Aumentada, pues a pesar de que ambas son virtuales, difieren
                  entre ellas en cuanto al tipo de percepción que recibimos y
                  los medios en que los recibimos.
                </p>
                <p>
                  <strong>Realidad Virtual:</strong> Representación de escenas o
                  imágenes de objetos producida por un sistema informático, que
                  da la sensación de su existencia real. (RAE).
                </p>
                <p>
                  <strong>Realidad Aumentada:</strong>&nbsp;Conjunto de
                  tecnologías que permiten que un usuario visualice parte del
                  mundo real a través de un dispositivo tecnológico con
                  información gráfica añadida por este dispositivo (Wikipedia)
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img src="https://i.imgur.com/c16qzFI.png" alt="" />
              </div>
              <div className="parrafo">
                <p>
                  Por lo tanto, con la Realidad Virtual (VR), experimentamos una
                  realidad totalmente inmersiva, viviéndola en primera persona.
                  La realidad virtual, necesita de un dispositivo de gafas VR
                  para que podamos vivir dicha experiencia. Estas gafas pueden
                  resultar muy sencillas, aportándonos simplemente un
                  experiencia visual, o muy completas si se acompañan de
                  accesorios para gestionar nuestros movimientos o nuestra
                  capacidad auditiva.
                </p>
                <p>
                  En lo que se refiere a la Realidad Aumentada (AR), no
                  viviremos una experiencia completamente inmersiva, si no que
                  aquellas imágenes generadas artificialmente se mostrarán en
                  nuestro mundo real a través de un dispositivo como un móvil
                  con cámara cualquiera.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img src="https://i.imgur.com/zY7SYWA.jpg" alt="" />
              </div>
              <div className="parrafo">
                <p>
                  <strong>
                    Como aplicar estas tecnologías al sector inmobiliario
                  </strong>
                </p>
                <p>
                  El uso de estas tecnologías en el sector inmobiliario ha
                  impactado con fuerza prácticamente en los primeros pasos de
                  estas tecnologías. Es posible que hayamos podido experimentar
                  dichos usos en algún caso. De todas formas me gustaría ofrecer
                  algunos ejemplos.
                </p>
                <p>
                  Uno de los primeros usos que se le dio a la realidad aumentada
                  en el sector inmobiliario, es en la venta de promociones
                  inmobiliarias. Las tradicionales maquetas de ejemplo de la
                  promoción a construir y comercializar, quedarán atrás en
                  detrimento de la Realidad Aumentada. Realizar dichas maquetas
                  en AR es más barato y produce un mayor efecto, por lo menos
                  hasta el momento. Además, permite diferentes modos de
                  visionado, por ejemplo, podemos elegir ver solo ciertas
                  plantas, o mostrar solamente puntos concretos del edificio.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img src="https://i.imgur.com/Opv9m4e.jpg" alt="" />
              </div>
              <div className="parrafo">
                <p>
                  <strong>Diseño de construcción</strong>
                </p>
                <p>
                  Como mencionamos anteriormente, la realidad virtual y
                  aumentada se han utilizado en la industria de la construcción
                  durante años. Sin embargo, cada vez más administradores de
                  propiedades y asociaciones de propietarios se están dando
                  cuenta de que estas herramientas también pueden ser útiles en
                  proyectos de construcción de menor escala. Por ejemplo, antes
                  de embarcarse en un gran proyecto de renovación, la realidad
                  virtual y aumentada puede dar a los propietarios e inversores
                  una idea de cómo será el resultado final. Las tecnologías de
                  realidad virtual y aumentada se utilizan cada vez más para
                  tomar importantes decisiones previas a la construcción.
                </p>
                <p>
                  <strong>Seguimiento del progreso del proyecto</strong>
                </p>
                <p>
                  Antes el dueño o administrador de una propiedad tenía que
                  visitar un sitio en persona para tener una idea de cómo iba el
                  proyecto de renovación. Gracias a la realidad virtual, ese ya
                  no es el caso.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img src="https://i.imgur.com/ES9jbBJ.jpg" alt="" />
              </div>
              <div className="parrafos">
                <p>
                  Propietarios, administradores de propiedades y asociaciones de
                  propietarios pueden aprovechar la tecnología de realidad
                  virtual para hacer visitas interactivas y virtuales de un
                  proyecto en construcción. Esto puede mejorar la comunicación
                  con los contratistas, asegurar que se cumplan los estándares
                  de diseño y finalizar los proyectos.
                </p>
                <p>
                  <strong>
                    ¿Cómo se utilizará la realidad virtual o la realidad
                    aumentada?
                  </strong>
                </p>
                <p>
                  La realidad virtual y la realidad aumentada son todavía
                  bastante nuevas. Sin embargo, las aplicaciones de software
                  están facilitando el uso de estas tecnologías con un
                  dispositivo móvil. El sistema iOS 11 de Apple incluye una
                  función ARKit que permite a los desarrolladores crear
                  aplicaciones y experiencias de realidad aumentada de una
                  manera más sencilla.
                </p>
                <p>
                  Por ejemplo, Wayfair ha integrado funciones en su aplicación
                  móvil en la que los usuarios pueden tener una vista previa del
                  aspecto que tendrá todo en una habitación, desde sillas hasta
                  lámparas de araña, y lo pueden hacer con solo pulsar un botón.
                </p>
                <p>
                  A medida que los smartphones evolucionan, las tecnologías de
                  realidad virtual Y realidad aumentada asociadas a ellos
                  también lo hacen. Estas son noticias extraordinarias para los
                  propietarios, administradores de propiedades y asociaciones de
                  propietarios. A medida que la realidad virtual y aumentada se
                  haga más asequible (y más fácil de usar), se convertirán en
                  herramientas aún más importantes a la hora de agilizar las
                  operaciones y procesos de administración de propiedades.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img src="https://i.imgur.com/GNMpn12.jpg" alt="" />
              </div>
              <div className="parrafos">
                <p>
                  La pandemia por el nuevo coronavirus obligó a que algunas
                  industrias cuyo desenvolvimiento dependía de una relación cara
                  a cara se reconviertan. Un ejemplo de esto es el sector
                  inmobiliario del Chaco el cual debió apelar a la creatividad y
                  la tecnología para mantener una estrecha relación con los
                  clientes y sus necesidades, pero a distancia.
                </p>
                <p>
                  <strong>¿En qué consistió esta reinvención?&nbsp;</strong>
                </p>
                <p>
                  Realidad aumentada, experiencia inmersiva e interactividad.
                  Más claro aún: una desarrolladora chaqueña del sector
                  inmobiliario utiliza tecnología PropTech la que permite
                  realizar recorridos virtuales basados en la gamificación en
                  los que la libertad de movimientos y la interactividad
                  posibilitan al cliente conocer cada rincón de lo que será su
                  próximo hogar con la opción de personalizarlo de manera
                  íntegra: agregar, quitar o cambiar, por ejemplo, el color de
                  las paredes, el estilo de los cerámicos, tipos de
                  revestimientos, la cocina o cualquier otro aspecto de la
                  vivienda.
                </p>
                <p>
                  Esta innovadora tecnología aplicada a un recorrido virtual con
                  realidad aumentada causa una sensación de identificación y
                  permanencia con el proyecto ya que las personas logran
                  visualizar su nuevo hogar con un mayor nivel de detalle que en
                  con un render estático, donde estas posibilidades están más
                  acotadas.
                </p>
                <p>
                  El recorrido simulado no es un vídeo. Es una aplicación
                  diseñada y programada para interactuar con el entorno dentro o
                  fuera del departamento a construir, en un servicio que hasta
                  ahora sólo se podía acceder en países como EEUU, España o
                  Inglaterra.
                </p>
                <p>
                  Actualmente, esta aplicación se está utilizando en lo que será
                  el edificio “SF745 Casas en Altura”, que se construirá en
                  Resistencia y cuyo desarrollo y construcción está a cargo de
                  la firma BASA. El mismo ofrece una oportunidad óptima para
                  brindarle al cliente una experiencia que de otra forma sería
                  imposible.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img src="https://i.imgur.com/o7Gjuf1.jpg" alt="" />
              </div>
              <div className="parrafos">
                <p>
                  “La pandemia y la cuarentena nos impulsó a la digitalización
                  de nuestros servicios, y desde ese momento buscamos la
                  posibilidad de implementar otra alternativa que vayan más allá
                  de un render estático y que nos permita diferenciarnos y
                  mejorar la experiencia con nuestros clientes.”, explicó Jorge
                  Almirón, Gerente Comercial
                </p>
                <p>
                  “Fue todo un desafío continuar con los proyectos relacionados
                  al desarrollo inmobiliario, y con ella llego la imposibilidad
                  de poder encontrarnos cara a cara con posibles clientes y
                  ofrecerles nuestros nuevos proyectos”, contó, al tiempo que
                  indicó que en este caso la aplicación muestra un semipiso de
                  tres dormitorios con tres baños.
                </p>
                <p>
                  <strong>
                    ¿Qué posibilidades de interacción tiene el recorrido virtual
                    “SF745 – Casas en Altura”?&nbsp;
                  </strong>
                </p>
                <p>
                  <strong>Con esta herramienta se puede:&nbsp;</strong>
                </p>
                <p>
                  • Cambiar materiales de pisos, paredes, mesadas de la cocina,
                  revestimiento en la cocina, mueble de alacena, mueble del bajo
                  mesada, puertas de armarios, material del vanitory en el baño,
                  mesada del vanitory, revestimiento del baño y revestimiento en
                  la ducha.
                </p>
                <p>
                  • Cambiar la vista que se ve desde el balcón según en qué piso
                  te encuentres, la vista también cambiará según estés en el
                  departamento del frente o del contra frente.
                </p>
                <p>
                  • Recorrer el departamento en tres modos distintos: primera
                  persona, aéreo y cámara.
                </p>
              </div>
              <div className="parrafos parrafos--fuente">
                <p>
                  Fuente:{" "}
                  <a
                    href="https://www.diarionorte.com/205042-interactividad-y-realidad-aumentada-asi-se-planifica-el-futuro-de-la-industria-inmobiliaria-en-el-chaco%C2%A0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.diarionorte.com/205042-interactividad-y-realidad-aumentada-asi-se-planifica-el-futuro-de-la-industria-inmobiliaria-en-el-chaco%C2%A0
                  </a>
                </p>
              </div>
            </div>
          )}

          {idNoticia === "2" && (
            <div className="notice__content">
              <div className="parrafo">
                <p>
                  Se emplazó la escultura 649 de Resistencia, en el boulevard
                  Sarmiento del 200 al 300 Se trata de la obra de autor
                  desconocido titulada “Cabeza de Tornado”; realizada en mármol
                  travertino y fue donada por la desarrollista inmobiliaria
                  BASA.
                </p>
                <p>
                  Además, de la inauguración de la escultura Nº 649 se repuso la
                  escultura Nº 360, titulada “Hombre” de Arturo Álvarez Lomba y
                  se iluminó la escultura N° 256 “Homenaje a una primavera” de
                  Selva Vega.
                </p>
                <p>
                  “Hoy es un día muy gratificante, porque acrecentamos el
                  patrimonio escultórico de la ciudad, que ya está próximo a las
                  700 esculturas emplazadas a cielo abierto, y porque con la
                  figura que se incorpora de “Guardianes del Patrimonio”, nos
                  estamos asegurando que cada obra será cuidada en su entorno
                  para embellecerla aún más. Con este tipo de acciones de las
                  empresas, vecinos y mecenas estamos construyendo de manera
                  colectiva la ciudad que habitamos y como siempre decimos, es
                  la mejor manera hacer del arte un bien de todos”, destacó el
                  presidente de la fundación Urunday; Fabriciano.
                </p>
                <p>
                  Durante el acto se produjo el nombramiento oficial de
                  “Guardianes del Patrimonio”
                </p>
                <p>
                  “En nombre de la comunidad, La municipalidad de Resistencia,
                  el Gobierno de la provincia del Chaco y la Fundación Urunday,
                  nombran a BASA como “Guardianes del Patrimonio” por su valioso
                  aporte al patrimonio escultórico público y por asumir el
                  compromiso de custodiar todas las obras de arte que habitan
                  este parterre y mantener este espacio en perfectas
                  condiciones”.
                </p>
                <p>
                  Por su parte el arquitecto Fernando Barrios, gerente de
                  producción de la firma; “Decidimos celebrar la finalización y
                  entrega del edificio “Boulevard Sarmiento 239” con la
                  inauguración de una nueva escultura para Resistencia.
                </p>
                <p>
                  Somos mecenas de la Fundación Urunday porque valoramos el
                  enorme aporte cultural y social de esta institución.
                  &nbsp;Posibilitar el emplazamiento de esta obra de arte en el
                  espacio público es un aporte a nuestro valioso patrimonio
                  escultórico.
                </p>
                <p>
                  Pero al mismo tiempo, tiene para nosotros un valor simbólico
                  muy especial. Cada vez que la veamos, al transitar por esta
                  cuadra de la ciudad, seguramente nos recordará que aún en los
                  tiempos más difíciles, con voluntad, perseverancia,
                  creatividad y honradez; pero fundamentalmente trabajando en
                  equipo, las metas se pueden alcanzar. Son tiempos duros para
                  nuestro país y para el mundo entero. No ha sido fácil
                  transitar el 2020, pero podemos decir con orgullo que somos
                  parte de un equipo comprometido con el trabajo y los valores
                  humanos; que con esfuerzo, compromiso y solidaridad generamos
                  empleo y contribuimos desde nuestro lugar a la reactivación
                  económica de nuestro sector.
                </p>
                <p>
                  Hoy es un día para celebrar. La Fundación y el municipio nos
                  han nombrado “Guardianes del Patrimonio” y asumimos el
                  compromiso de custodiar, no sólo la obra de arte emplazada,
                  sino todas las que habitan este parterre. En adelante,
                  tomaremos la responsabilidad de mantener este espacio en
                  perfectas condiciones y colaborar activamente con la buena
                  salud de estas esculturas y su entorno” finalizó el mecenas.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <CarouselComp
                  images={itemNoticia.slideImg}
                  showThumbnails={true}
                  autoPlay={true}
                />
              </div>
              <div className="img-container d-flex justify-content-center">
                <iframe
                  width="860"
                  height="615"
                  src="https://www.youtube.com/embed/It2KGd9Y9kg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="img-container d-flex justify-content-center">
                <iframe
                  width="860"
                  height="615"
                  src="https://www.youtube.com/embed/ZnqlX8apsxA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="img-container d-flex justify-content-center">
                <iframe
                  width="860"
                  height="615"
                  src="https://www.youtube.com/embed/A1BJJo4z-YY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="img-container d-flex justify-content-center">
                <iframe
                  width="860"
                  height="515"
                  src="https://www.youtube.com/embed/Pl0YGcGMrcE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="parrafo">
                <p>
                  Fuente: <br />
                  <a
                    href="http://www.chacodiapordia.com/2021/03/18/se-emplazo-en-resistencia-la-escultura-649-y-se-incorporo-la-figura-de-guardianes-del-patrimonio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.chacodiapordia.com/2021/03/18/se-emplazo-en-resistencia-la-escultura-649-y-se-incorporo-la-figura-de-guardianes-del-patrimonio/
                  </a>
                </p>
                <p>
                  <a
                    href="https://datachaco.com/contenido/115593/emplazaron-la-escultura-n649-de-resistencia-en-el-boulevard-sarmiento"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://datachaco.com/contenido/115593/emplazaron-la-escultura-n649-de-resistencia-en-el-boulevard-sarmiento
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.diariochaco.com/noticia/instalaron-la-escultura-ndeg-649-en-resistencia-e-incorporaron-la-figura-de-guardianes-del"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.diariochaco.com/noticia/instalaron-la-escultura-ndeg-649-en-resistencia-e-incorporaron-la-figura-de-guardianes-del
                  </a>
                </p>

                <p>
                  <a
                    href="http://www.diariotag.com/noticias/locales/emplazaron-la-estatua-ndeg-649-e-incorporaron-la-figura-de-guardianes-del"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.diariotag.com/noticias/locales/emplazaron-la-estatua-ndeg-649-e-incorporaron-la-figura-de-guardianes-del
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.diarionorte.com/203008-se-emplazo-la-escultura-n-649-y-con-ella-se-incorporo-la-figura-de-guardianes-del-patrimonio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.diarionorte.com/203008-se-emplazo-la-escultura-n-649-y-con-ella-se-incorporo-la-figura-de-guardianes-del-patrimonio
                  </a>
                </p>
                <p>
                  <a
                    href="https://mediosyestrategias.com/resistencia-inauguro-su-escultura-649/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://mediosyestrategias.com/resistencia-inauguro-su-escultura-649/
                  </a>
                </p>
                <p>
                  <a
                    href="http://informechaco.com.ar/2021/03/16/con-el-emplazamiento-de-la-escultura-649-se-crea-la-figura-de-guardianes-del-patrimonio/#.YFPmIq6bvjA.whatsapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://informechaco.com.ar/2021/03/16/con-el-emplazamiento-de-la-escultura-649-se-crea-la-figura-de-guardianes-del-patrimonio/#.YFPmIq6bvjA.whatsapp
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.noticiasdelparana.com.ar/NuevaEdicion/2021/03/18/resistencia-se-emplazo-la-escultura-na-649-y-con-ella-se-incorporo-la-figura-de-guardianes-del-patrimonio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.noticiasdelparana.com.ar/NuevaEdicion/2021/03/18/resistencia-se-emplazo-la-escultura-na-649-y-con-ella-se-incorporo-la-figura-de-guardianes-del-patrimonio/
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.tn24.com.ar/2021/03/resistencia-tiene-su-escultura-no-649/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.tn24.com.ar/2021/03/resistencia-tiene-su-escultura-no-649/
                  </a>
                </p>
                <p>
                  <a
                    href="https://facundoquiroga.com/959resistencia-ya-cuenta-con-la-figura-de-guardianes-del-patrimonio-y-su-escultura-n-649/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://facundoquiroga.com/959resistencia-ya-cuenta-con-la-figura-de-guardianes-del-patrimonio-y-su-escultura-n-649/
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.diariochaco.com/noticia/es-muy-importante-mantener-la-identidad-de-resistencia-afirmo-el-intendente-martinez-en-la"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.diariochaco.com/noticia/es-muy-importante-mantener-la-identidad-de-resistencia-afirmo-el-intendente-martinez-en-la
                  </a>
                </p>
              </div>
            </div>
          )}

          {idNoticia === "3" && (
            <div className="notice__content">
              <div className="parrafo">
                <p>
                  El próximo jueves, a las 10:30 de la mañana, se emplazará la
                  escultura Nº649 de la ciudad, en el boulevard Sarmiento del
                  200 al 300 y con ella se incorpora al proyecto de la Fundación
                  Urunday la figura de ‘Guardianes del Patrimonio’.
                </p>
                <p>
                  Se trata de la obra de autor desconocido titulada ‘Cabeza de
                  Tornado’, realizada en mármol travertino y fue donada por la
                  desarrollista inmobiliaria BASA que se suma además de manera
                  activa a la conservación del patrimonio escultórico de nuestra
                  ciudad, transformándose en los primeros “Guardianes’.
                </p>
                <p>
                  ”En este trabajo en conjunto entre empresas del medio y la
                  Fundación Urunday se encuentra la oportunidad de consolidar la
                  enorme tarea de preservación de nuestro patrimonio artístico,
                  que hace de nuestra ciudad un museo a cielo abierto único en
                  el mundo”, destacó Fabriciano.
                </p>
                <p>
                  Además, de la inauguración de la escultura Nº649 se repondrá
                  la escultura Nº360, titulada ‘Hombre’ de Arturo Álvarez Lomba
                  y se iluminará la escultura N°256 ‘Homenaje a una primavera’
                  de Selva Vega.
                </p>
                <p>
                  Consultado al respecto Jorge Almirón, gerente comercial de
                  BASA, sostuvo que “decidimos celebrar la finalización y
                  entrega del edificio con la inauguración de una nueva
                  escultura para Resistencia y además estamos muy felices y
                  orgullosos que nos hayan nombrado ‘Guardianes del Patrimonio.
                  Esta tarea consiste además de acrecentar el patrimonio
                  escultórico, cuidar y embellecer el entorno de las esculturas
                  artísticas que están frente a nuestra obra recién finalizada,
                  el edificio Boulevard Sarmiento 239″.
                </p>
                <p>
                  A lo expuesto, el arquitecto Fernando Barrios, gerente de
                  producción de la firma agregó que, “hoy es un día para
                  celebrar. La Fundación y el Municipio nos han nombrado
                  ‘Guardianes del Patrimonio’ y asumimos el compromiso de
                  custodiar, no sólo la obra de arte emplazada, sino todas las
                  que habitan este parterre. En adelante, tomaremos la
                  responsabilidad de mantener este espacio en perfectas
                  condiciones y colaborar activamente con la buena salud de
                  estas esculturas”.
                </p>
                <p>
                  Finalmente, Sebastián Sánchez, gerente financiero de BASA
                  expresó que, “encontramos en los proyectos de la Fundación
                  Urunday una gran oportunidad para llevar adelante nuestra
                  política de responsabilidad social empresaria y comprometernos
                  con la ciudad que habitamos”.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/zrTFfgB.png"
                  alt="Imágen noticia"
                />
              </div>
              <div className="parrafo">
                <p>
                  Fuente: <br />
                  <a
                    href="http://www.chacodiapordia.com/2021/03/16/ciudad-de-las-esculturas-una-empresa-se-transforma-en-guardianes-del-patrimonio-con-la-escultura-no649/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.chacodiapordia.com/2021/03/16/ciudad-de-las-esculturas-una-empresa-se-transforma-en-guardianes-del-patrimonio-con-la-escultura-no649/
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.noticiasdelparana.com.ar/NuevaEdicion/2021/03/16/resistencia-basa-se-transforma-en-guardianes-del-patrimonio-con-la-escultura-649/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.noticiasdelparana.com.ar/NuevaEdicion/2021/03/16/resistencia-basa-se-transforma-en-guardianes-del-patrimonio-con-la-escultura-649/
                  </a>
                </p>
                <p>
                  <a
                    href="https://agenciafoco.com.ar/detalle_noticia/con-el-emplazamiento-de-la-escultura-649-se-crea-la-figura-de-guardianes-del-patrimonio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://agenciafoco.com.ar/detalle_noticia/con-el-emplazamiento-de-la-escultura-649-se-crea-la-figura-de-guardianes-del-patrimonio
                  </a>
                </p>
                <p>
                  <a
                    href="https://chacoonline.com.ar/contenido/17874/resistencia-emplazara-su-escultura-649"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://chacoonline.com.ar/contenido/17874/resistencia-emplazara-su-escultura-649
                  </a>
                </p>
                <p>
                  <a
                    href="https://cadenatotal.com.ar/con-el-emplazamiento-de-la-escultura-649-se-crea-la-figura-de-guardianes-del-patrimonio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://cadenatotal.com.ar/con-el-emplazamiento-de-la-escultura-649-se-crea-la-figura-de-guardianes-del-patrimonio/
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.chacohoy.com/noticias/view/243245"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.chacohoy.com/noticias/view/243245
                  </a>
                </p>
                <p>
                  <a
                    href="https://diarioprimeralinea.com.ar/manana-se-emplaza-la-escultura-649-en-resistencia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://diarioprimeralinea.com.ar/manana-se-emplaza-la-escultura-649-en-resistencia/
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.diario21.tv/notix2/movil2/noticia/152096_resistencia-tendraacute-quotguardianes-del-patrimonioquot-con-el-emplazamiento-de-la-escultura-ndeg649.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.diario21.tv/notix2/movil2/noticia/152096_resistencia-tendraacute-quotguardianes-del-patrimonioquot-con-el-emplazamiento-de-la-escultura-ndeg649.htm
                  </a>
                </p>
              </div>
            </div>
          )}

          {idNoticia === "4" && (
            <div className="notice__content">
              <div className="parrafo">
                <p>
                  A pocos días de su inauguración, la empresa BASA destaca al
                  nuevo edificio de departamentos residenciales Boulevard
                  Sarmiento como un aporte arquitectónico urbano y paisajístico
                  a 150 metros de la Plaza 25 de Mayo de la capital chaqueña.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Nuevo y Sofisticado Concepto</strong>
                </p>

                <p>
                  La obra expresa un concepto sofisticado en términos de
                  espacialidad, terminaciones, amenities y equipamientos en una
                  de las ubicaciones más exclusivas de la ciudad, Av. Sarmiento
                  239; con plantas de 1 a 3 dormitorios, dependencias de
                  servicio y balcones aterrazados.
                </p>
                <p>
                  Fernando Barrios, gerente de producción de BASA, resaltó que
                  “Boulevar Sarmiento es un producto de alta gama atado a las
                  condiciones de la demanda local que está en el corazón
                  estratégico de la capital chaqueña” y remarcó la importancia
                  “de la inversión privada que permite este nuevo desarrollo
                  local como aporte al crecimiento de una ciudad que es faro del
                  nordeste argentino.”
                </p>
                <p>
                  Asimismo, destacó que “a pesar del contexto de pandemia, de
                  las complicaciones en la industria de la construcción y en
                  cada uno de los actores de este sector, pudimos consolidar una
                  obra de clase A y generar puestos de trabajo durante estos 40
                  meses de trabajo.
                </p>
                <p>
                  Boulevard Sarmiento consta de 5.500 metros cuadrados de
                  construcción, con 51 departamentos de 1, 2 y 3 dormitorios con
                  una superficie de 70, 103 y 140 m2 respectivamente ubicados en
                  12 niveles; más planta baja y azotea. Cuenta con salón de usos
                  múltiples con parrilla totalmente equipado, piscina, área de
                  niños, solárium y gimnasio.
                </p>
                <p>
                  En cuanto al acceso vehicular tiene 42 cocheras con bauleras
                  con acceso diferenciado por rampa fija y bike parking.
                </p>
                <p>&nbsp;</p>
                <p>
                  <b>INVERSIÓN CHAQUEÑA</b>
                </p>
                <p>
                  Basa es una empresa de origen chaqueño comprometida con la
                  construcción de confianza a través de proyectos e inversiones
                  inmobiliarias.
                </p>

                <p>
                  En casi 20 años de probada trayectoria y solvencia ha
                  desarrollado y concretado más de 300 proyectos en la ciudad de
                  Resistencia y otras ciudades del nordeste argentino,
                  priorizando los mejores estándares posibles de calidad,
                  eficiencia y diseño arquitectónico.
                </p>
                <p>
                  Desde la empresa, reconocen destacarse por “brindar un
                  servicio de gestión integral a través de un equipo profesional
                  de especialistas en cada una de las fases de los proyectos;
                  desde la selección del mejor terreno, planificación financiera
                  y de negocios hasta el desarrollo, construcción,
                  comercialización y post venta con el objetivo de maximizar la
                  inversión de nuestros clientes e inversores en términos de
                  calidad, diseño y rentabilidad y mejorar la calidad de vida de
                  la comunidad en general.
                </p>
              </div>
              <div className="img-container d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/0zIYpaJ.png"
                  alt="Imágen Noticia"
                />
              </div>
              <div className="parrafo">
                <p>Fuente: Diario Norte, 21 de Febrero de 2021</p>
                <p>
                  <a
                    href="https://www.diarionorte.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.diarionorte.com
                  </a>
                </p>
              </div>
            </div>
          )}

          {idNoticia === "8" && (
            <>
              <div className="notice__subtitle d-flex justify-content-center">
                <h2>Seleccionados por AMERIAN como Socios Estratégicos</h2>
              </div>
              <div className="notice__content d-flex justify-content-center">
                <Row>
                  <Col sm={4}>
                    <img
                      src="https://i.imgur.com/AfaGUk4.png"
                      alt=""
                      width={"100%"}
                    />
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={7}>
                    <div className="parrafo">
                      <p>
                        En BASA Desarrollo Inmobiliario nos sentimos orgullosos
                        de haber sido seleccionados por AMERIAN como socios
                        estratégicos del desarrollo de su plan de expansión en
                        la región NEA de Argentina y en la República del
                        Paraguay.
                      </p>
                      <p>
                        La cadena AMERIAN (www.amerian.com) es la primera cadena
                        argentina de hoteles que inició sus actividades en la
                        ciudad de Córdoba en 1992. Bajo sus marcas AMERIAN (4 y
                        5 estrellas), MERIT (3 estrellas superior) e ITEM (2
                        estrellas), suman más de 20 hoteles propios y otros
                        operados bajo el sistema de administración, franquicias
                        y modelo Condo-Hotel.
                      </p>
                      <p>
                        Somos responsables de la selección y gestión del
                        terreno, diseño integral, estructuración,
                        comercialización y administración posterior de complejos
                        de usos mixtos que incluyan dentro del mismo edificio un
                        hotel AMERIAN, residencias, oficinas corporativas,
                        cocheras, áreas comerciales y servicios complementarios.
                      </p>
                      <p>
                        Este esquema innovador de negocio inmobiliario destaca
                        una rentabilidad proyectada superior a cualquier otro
                        esquema tradicional que se sustenta en la combinación de
                        los usos complementarios del proyecto en locaciones
                        estratégicas con la transparencia y el respaldo
                        combinado de las marcas AMERIAN y BASA Desarrollo
                        Inmobiliario.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Container>
      </div>
      <Contact />
      <Ubicacion />
    </>
  );
};

export default Noticia;
