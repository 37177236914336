import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./NotFound.scss";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <section className="sec_notFound">
      <Container>
        <Row>
          <Col md={5} sm={12}>
            <img
              src="https://img.freepik.com/vector-gratis/ilustracion-concepto-pagina-no-encontrada_114360-1869.jpg?w=2000"
              alt="404-imagen"
              width={"100%"}
            />
          </Col>
          <Col md={1} sm={0}></Col>
          <Col
            md={6}
            sm={12}
            className="sec_notFound__text d-flex flex-column align-items-center justify-content-center"
          >
            <h1 className="title-error">ERROR 404</h1>
            <h3>
              Lo siento, no se pudo encontrar la página a la que intentas
              acceder.
            </h3>
            <Link to="/">
              <Button variant="outline-light" size="lg">
                {"< Volver al Inicio"}
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
