import "./Constructora.scss";
import MetaData from "../Layouts/MetaData";
import { BTCarousel } from "../../library/BTCarousel/BTCarousel";
import { heroConstructora } from "../utils/heros";

const Constructora = () => {
  return (
    <>
      <section className="sec-constructora">
        <MetaData title="Constructora | BASA Desarrollos" />
        <BTCarousel {...heroConstructora} />
      </section>
    </>
  );
};

export default Constructora;
