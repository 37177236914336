import React from "react";
import ImageGallery from "react-image-gallery";

const state = {
  showIndex: false,
  showBullets: false,
  infinite: true,
  showThumbnails: true,
  showFullscreenButton: true,
  showGalleryFullscreenButton: true,
  showPlayButton: false,
  showGalleryPlayButton: true,
  showNav: true,
  isRTL: false,
  slideDuration: 700,
  slideInterval: 5000,
  slideOnThumbnailOver: false,
  thumbnailPosition: "bottom",
  useWindowKeyDown: true,
};

const CarouselComp = ({
  images,
  showThumbnails,
  thumbnailPosition,
  autoPlay,
}) => {
  return (
    <ImageGallery
      items={images}
      lazyLoad={true}
      infinite={state.infinite}
      showBullets={state.showBullets}
      showFullscreenButton={
        state.showFullscreenButton && state.showGalleryFullscreenButton
      }
      showPlayButton={state.showPlayButton && state.showGalleryPlayButton}
      showThumbnails={showThumbnails}
      showIndex={state.showIndex}
      showNav={state.showNav}
      isRTL={state.isRTL}
      thumbnailPosition={thumbnailPosition}
      slideDuration={state.slideDuration}
      slideInterval={state.slideInterval}
      slideOnThumbnailOver={state.slideOnThumbnailOver}
      additionalClass="app-image-gallery"
      useWindowKeyDown={state.useWindowKeyDown}
      autoPlay={autoPlay}
    />
  );
};

export default CarouselComp;
