import React from "react";
import { Row } from "react-bootstrap";
import "./SeparadorGestion.scss";

const SeparadorProyecto = () => {
  return (
    <section className="sec_gestion">
      <Row className="row_gestion">
        <h3>GESTIÓN INTEGRAL</h3>
        <br />
        <br />
        <p>
          Nos destacamos por una gestión integral que busca maximizar valor
          <br />
          agregado a través de excelencia en procesos, optimización de costos,
          <br />
          cumplimiento de plazos pactados, relacionamiento estratégico con
          <br />
          proveedores y ética profesional.
          <br />
          <br />
          Buscamos trascender para mejorar el nivel y la calidad de vida de
          <br />
          nuestros clientes, inversores y la comunidad en general.
        </p>
      </Row>
    </section>
  );
};

export default SeparadorProyecto;
