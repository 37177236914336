// Libraries
import React from "react";
import Contact from "../Layouts/Contact/Contact";

// Components
import MetaData from "../Layouts/MetaData";
import DesarrollosSec from "./DesarrollosSec/DesarrollosSec";
import Hero from "./Hero/Hero";

// Styles & Icons
import SeparadorDatos from "./SeparadorDatos/SeparadorDatos";
import SeparadorGestion from "./SeparadorGestion/SeparadorGestion";
import Ubicacion from "./Ubicacion/Ubicacion";

export const Home = () => {
  return (
    <>
      <MetaData title="Home | BASA Desarrollos" />
      <Hero />
      <SeparadorGestion />
      <SeparadorDatos />
      <DesarrollosSec />
      <Contact />
      <Ubicacion />
    </>
  );
};

export default Home;
