export const profesionales = [
  {
    img: "https://i.imgur.com/BdKRbaP.jpg",
    name: "Ing. Sebastian",
    lastname: "Sánchez",
    position: "Socio Gerente Financiero",
  },
  {
    img: "https://i.imgur.com/sEkfvhi.jpg",
    name: "Arq. Fernando",
    lastname: "Barrios",
    position: "Socio Gerente Producción",
  },
  {
    img: "https://i.imgur.com/RvBAoPv.jpg",
    name: "Ing. Santiago",
    lastname: "Galíndez",
    position: "Supervisión de Obras",
  },
  {
    img: "https://i.imgur.com/mAf9qmM.jpg",
    name: "DG. Rodolfo",
    lastname: "Vassallo",
    position: "Asesor Comercial y Atención al Cliente",
  },
  {
    img: "https://i.imgur.com/tVBSHj3.jpg",
    name: "Arq. Silvana",
    lastname: "Romero",
    position: "Oficina Técnica y Diseño",
  },
  {
    img: "https://i.imgur.com/WVATFZn.jpg",
    name: "Silvia",
    lastname: "Fernández",
    position: "Administración y Pagos",
  },
  {
    img: "https://i.imgur.com/ouBeoUu.jpg",
    name: "Lorena",
    lastname: "Zalazar",
    position: "Administración y RRHH",
  },
  {
    img: "https://i.imgur.com/OCj0jI2.jpg",
    name: "Sergio",
    lastname: "Zambón",
    position: "Compras y Suministros",
  },
  {
    img: "https://i.imgur.com/v2H6eQK.jpg",
    name: "Roberto",
    lastname: "Alegre",
    position: "Logística y Suministros",
  },
  {
    img: "https://i.imgur.com/a7OzyPC.jpg",
    name: "Gabriel",
    lastname: "Ojeda",
    position: "Jefe de Depósito y Logística",
  },
];
