import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Detalle from "../Detalle/Detalle";

// Styles & Icons
import "./Desarrollos.scss";
import MetaData from "../Layouts/MetaData";
import Despliegue from "./Despliegue/Despliegue";

// Nav Items
const sidebarNavItems = [
  {
    display: "Todos",
    to: "/desarrollos/todos",
    section: "todos",
  },
  {
    display: "Urbanos",
    to: "/desarrollos/urbanos",
    section: "urbanos",
  },
  {
    display: "Residenciales",
    to: "/desarrollos/residenciales",
    section: "residenciales",
  },
  {
    display: "Casas en altura",
    to: "/desarrollos/altura",
    section: "altura",
  },
  {
    display: "Tierra",
    to: "/desarrollos/tierra",
    section: "tierra",
  },
];

const Desarrollos = () => {
  const [desActiveIndex, setDesActiveIndex] = useState(0);
  const [desStepWidth, setDesStepWidth] = useState(0);
  const desRef = useRef();
  const desIndicatorRef = useRef();
  const desLocation = useLocation();

  const params = useParams();
  const category = params.category;
  const catRegex = /todos|urbanos|residenciales|altura|tierra/i;
  const isCat = catRegex.test(category);

  useEffect(() => {
    // const desNavItem = desRef.current.querySelector(".des-item");
    switch (desActiveIndex) {
      case 1:
        setDesStepWidth(75);
        break;

      case 2:
        setDesStepWidth(86);
        break;

      case 3:
        setDesStepWidth(100);
        break;
      case 4:
        setDesStepWidth(110);
        break;

      default:
        break;
    }
  }, [desActiveIndex]);

  //cambiar index activado
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[2];
    if (curPath === undefined) {
      return <Redirect to="/desarrollos/todos" />;
    }
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    setDesActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [desLocation]);

  return (
    <section className="sec_desarrollos-nav">
      <MetaData title="Desarrollos | BASA Desarrollos" />

      {isCat ? (
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className="nav_desarrollos"
        >
          <Container fluid className="cont_desarrollos">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav ref={desRef}>
                <div
                  ref={desIndicatorRef}
                  id="marker-desarrollos"
                  style={{
                    transform: `translateX(${
                      desActiveIndex * desStepWidth
                    }px) translateY(50%)`,
                  }}
                ></div>

                {sidebarNavItems.map((item, index) => (
                  <Nav.Link className={`nav-item nav-item-p des-item`} to="/">
                    <Link
                      to={item.to}
                      key={index}
                      className={`_link-nav ${
                        desActiveIndex === index ? "active" : ""
                      }`}
                    >
                      {item.display}
                    </Link>
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <>
          <Detalle />
        </>
      )}
      <Despliegue />
    </section>
  );
};

export default Desarrollos;
