import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { desarrollos } from "../utils/proyectos";
import CarouselComp from "../Layouts/CarouselComp/CarouselComp";
import ElementoDesarrollo from "../Layouts/ElementoDesarrollo/ElementoDesarrollo";
import "./Detalle.scss";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MetaData from "../Layouts/MetaData";
import { useEffect } from "react";

const Detalle = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  const params = useParams();
  const detalleTitle = params.category;
  const itemDesarrollos = desarrollos.find((i) => i.title === detalleTitle);
  const indexActual = desarrollos.indexOf(itemDesarrollos);
  var itemAnterior;
  var itemPosterior;

  if (desarrollos[indexActual - 1]) {
    itemAnterior = desarrollos[indexActual - 1];
    // console.log(desarrollos[desarrollos.length]);
  } else {
    itemAnterior = desarrollos[desarrollos.length - 1];
  }

  if (desarrollos[indexActual + 1] !== undefined) {
    itemPosterior = desarrollos[indexActual + 1];
  } else {
    itemPosterior = desarrollos[0];
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.category]);

  return (
    <section className="sec_detalle">
      <MetaData title={` ${itemDesarrollos.title} | BASA Desarrollos`} />

      {!isMobileDevice && (
        <>
          {itemDesarrollos.imgArr_portada ? (
            <Carousel className="car" id="inicio" interval={4500}>
              {itemDesarrollos.imgArr_portada.map((img, index) => (
                <Carousel.Item key={index} className="car-item">
                  <img
                    className="car-img-hero"
                    loading="lazy"
                    src={
                      isMobileDevice
                        ? itemDesarrollos.imgArr_portadaMobile[index]
                        : img
                    }
                    alt={`${index} slide`}
                  />
                  <Carousel.Caption className="car-item-caption">
                    <div className="car-item-caption__container">
                      <h1 className="h1_title">{itemDesarrollos.title}</h1>
                      <h6 className="h6_dato">
                        {itemDesarrollos.category_title}
                      </h6>
                      <h6 className="h6_dato">{itemDesarrollos.direccion}</h6>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Row className="row_img-ext">
              <img
                src={
                  isMobileDevice
                    ? itemDesarrollos.img_ext_mobile
                    : itemDesarrollos.img_ext
                }
                alt=""
                className="img_ext"
              />
              <div className="div_text-img">
                <h1 className="h1_title">{itemDesarrollos.title}</h1>
                <h6 className="h6_dato">{itemDesarrollos.category_title}</h6>
                <h6 className="h6_dato">{itemDesarrollos.direccion}</h6>
              </div>
            </Row>
          )}
        </>
      )}

      {isMobileDevice && (
        <>
          {itemDesarrollos.imgArr_portadaMobile ? (
            <Carousel className="car" id="inicio" interval={4500}>
              {itemDesarrollos.imgArr_portada.map((img, index) => (
                <Carousel.Item key={index} className="car-item">
                  <img
                    className="car-img-hero"
                    loading="lazy"
                    src={
                      isMobileDevice
                        ? itemDesarrollos.imgArr_portadaMobile[index]
                        : img
                    }
                    alt={`${index} slide`}
                  />
                  <Carousel.Caption className="car-item-caption">
                    <div className="car-item-caption__container">
                      <h1 className="h1_title">{itemDesarrollos.title}</h1>
                      <h6 className="h6_dato">
                        {itemDesarrollos.category_title}
                      </h6>
                      <h6 className="h6_dato">{itemDesarrollos.direccion}</h6>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Row className="row_img-ext">
              <img
                src={
                  isMobileDevice
                    ? itemDesarrollos.img_ext_mobile
                    : itemDesarrollos.img_ext
                }
                alt=""
                className="img_ext"
              />
              <div className="div_text-img">
                <h1 className="h1_title">{itemDesarrollos.title}</h1>
                <h6 className="h6_dato">{itemDesarrollos.category_title}</h6>
                <h6 className="h6_dato">{itemDesarrollos.direccion}</h6>
              </div>
            </Row>
          )}
        </>
      )}

      <Row className="row_desc">
        <div className="div_width"></div>
        <h2 className="h2_title">
          {itemDesarrollos.title} | {itemDesarrollos.category_title}
        </h2>
        <h3 className="h3_state">{itemDesarrollos.state}</h3>
        <p className="p_desc">{itemDesarrollos.desc}</p>
        <br />
        <br />
        <Row className="row_downloads">
          <a className="a_broch" href={itemDesarrollos.broschure}>
            <b>
              <ArrowDropDownIcon fontSize="large" />
              <b>Brochure</b>
            </b>
          </a>
          <a className="a_planos" href={itemDesarrollos.planos}>
            <b>
              <ArrowDropDownIcon fontSize="large" />
              <b>Planos</b>
            </b>
          </a>
          <a href="#contacto" className="a_consulta">
            <b>Consulta</b>
          </a>
        </Row>
      </Row>
      <Row className="row_images">
        {itemDesarrollos.img_arr && (
          <>
            {/*============================== SLIDER 1 ==============================*/}
            <Col md={12} className="col_carousel">
              <CarouselComp
                images={
                  isMobileDevice && itemDesarrollos.img_arr_mobile
                    ? itemDesarrollos.img_arr_mobile
                    : itemDesarrollos.img_arr
                }
                showThumbnails={true}
                autoPlay={true}
              />
            </Col>
          </>
        )}

        {itemDesarrollos.img_arr_2 && (
          <>
            {/*============================== SLIDER 2 ==============================*/}
            <Col md={12} className="col_carousel">
              <CarouselComp
                images={
                  isMobileDevice && itemDesarrollos.img_arr_mobile
                    ? itemDesarrollos.img_arr_2_mobile
                    : itemDesarrollos.img_arr_2
                }
                showThumbnails={true}
                autoPlay={true}
              />
            </Col>
          </>
        )}

        {/*============================== KOOLA ==============================*/}
        {itemDesarrollos.title === "P903" && (
          <Col md={12}>
            <iframe
              frameBorder="0"
              loading="lazy"
              title="kuula360"
              scrolling="no"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowFullScreen={true}
              className="visor360"
              src={itemDesarrollos.vr_link}
            ></iframe>
          </Col>
        )}
      </Row>
      <Row className="row_ubication">
        <iframe
          src={itemDesarrollos.ubication}
          width="1920"
          height="400"
          title="kuula360"
          // style={"border:0;"}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Row>
      <Row className="row_similares_proyectos">
        <Col xs={12} md={6}>
          <Row className="row_similares">
            <h1 className="h1_similares">Proyecto anterior</h1>
          </Row>
          {itemAnterior && (
            <ElementoDesarrollo
              {...itemAnterior}
              category={itemAnterior.category_title}
            />
          )}
        </Col>
        <Col xs={12} md={6}>
          <Row className="row_similares">
            <h1 className="h1_similares">Proyecto siguiente</h1>
          </Row>
          {itemPosterior && (
            <ElementoDesarrollo
              {...itemPosterior}
              category={itemPosterior.category_title}
            />
          )}
        </Col>
      </Row>
    </section>
  );
};

export default Detalle;
