import React from "react";
import { Col, Row } from "react-bootstrap";
import "./SeparadorDatos.scss";
import calendario from "../../../assets/calendario.svg";
import casco from "../../../assets/casco.svg";
import llave from "../../../assets/llave.svg";
import edificio from "../../../assets/edificio.svg";

const SeparadorDatos = () => {
  return (
    <section className="datos-sec">
      <Row className="row-datos justify-content-md-center">
        <Col className="col-elemento" md="auto">
          <img src={calendario} className="svg-elementos" alt="" />
          <h6 className="texto-elementos">
            +20 años de
            <br /> experiencia
          </h6>
        </Col>
        <Col className="col-elemento" md="auto">
          <img src={casco} className="svg-elementos" alt="" />
          <h6 className="texto-elementos">
            +100.000 m2
            <br /> construidos
          </h6>
        </Col>
        <Col className="col-elemento" md="auto">
          <img src={llave} className="svg-elementos" alt="" />
          <h6 className="texto-elementos">
            +300 obras
            <br /> realizadas
          </h6>
        </Col>
        <Col className="col-elemento" md="auto">
          <img src={edificio} className="svg-elementos" alt="" />
          <h6 className="texto-elementos">
            6 edificios
            <br /> terminados
          </h6>
        </Col>
      </Row>
    </section>
  );
};

export default SeparadorDatos;
