import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./BTCarousel.scss";

export interface BTCarouselProps {
  items: {
    title?: string;
    subtitle?: string;
    button?: {
      variant: string;
      label: string;
      href?: string;
      to?: string;
    };
    image?: string;
    imageMobile?: string;
  }[];
  interval: number;
  className?: string;
}

export const BTCarousel = ({ items, interval, className }: BTCarouselProps) => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  return (
    <Carousel
      className={`car ${className ? className : ""}`}
      id="inicio"
      interval={interval}
    >
      {items.map((item) => (
        <Carousel.Item className="car-item">
          <img
            className="car-img-hero"
            loading="lazy"
            src={isMobileDevice ? item.imageMobile : item.image}
            alt={items.indexOf(item) + " slide"}
          />
          <Carousel.Caption className="car-item-caption">
            <div className="car-item-caption__container">
              {item.title && <h3>{item.title.toUpperCase()}</h3>}
              {item.subtitle && <p>{item.subtitle}</p>}
              {item.button && (
                <>
                  {item.button.to ? (
                    <Link to={item.button.to}>
                      <Button {...item.button}>{item.button.label}</Button>
                    </Link>
                  ) : (
                    <Button {...item.button} target="_blank">
                      {item.button.label}
                    </Button>
                  )}
                </>
              )}
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
