import React from "react";
import Ubicacion from "../Home/Ubicacion/Ubicacion";
import Contact from "../Layouts/Contact/Contact";
import { noticias } from "../utils/noticias";
import NoticiaElement from "./NoticiaElement/NoticiaElement";
import "./Prensa.scss";
import Portada from "./Portada/Portada";
import MetaData from "../Layouts/MetaData";

const Prensa = () => {
  return (
    <>
      <section className="sec_prensa">
        <MetaData title="Prensa | BASA Desarrollos" />

        <Portada />
        {noticias.map((el, i) => (
          <NoticiaElement
            title={el.title}
            img={el.img}
            desc={el.desc}
            id={el.id}
            last={i === noticias.length - 1}
            href={el.href}
          />
        ))}
      </section>
      <Contact />
      <Ubicacion />
    </>
  );
};

export default Prensa;
