import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ElementoDesarrollo.scss";

const ElementoDesarrollo = ({
  title,
  category,
  direccion,
  img,
  img_mobile,
}) => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  return (
    <>
      {title !== "Torres Magistrados" ? (
        <Link to={`/desarrollos/${title}`} className="link_detalle">
          <Row className="row_elementoDes">
            <Col xs={12} className={"col_contenedor-img d-block"}>
              <img
                src={isMobileDevice ? img_mobile : img}
                alt=""
                className="img_elemento"
                width={"100%"}
              />
              <div className="div_dat-elemento-1">
                <div className="div_dat-elemento-2">
                  <h1 className="h1_dat-elemento">{title}</h1>
                  <h6 className="h6_dat-elemento">{direccion}</h6>
                  <h6 className="h6_dat-elemento">{category}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Link>
      ) : (
        <a
          href={`https://torresmagistrados.com`}
          target="_blank"
          className="link_detalle"
          rel="noreferrer"
        >
          <Row className="row_elementoDes">
            <Col xs={12} className={"col_contenedor-img d-block"}>
              <img
                src={isMobileDevice ? img_mobile : img}
                alt=""
                className="img_elemento"
                width={"100%"}
              />
              <div className="div_dat-elemento-1">
                <div className="div_dat-elemento-2">
                  <h1 className="h1_dat-elemento">{title}</h1>
                  <h6 className="h6_dat-elemento">{direccion}</h6>
                  <h6 className="h6_dat-elemento">{category}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </a>
      )}
    </>
  );
};

export default ElementoDesarrollo;
