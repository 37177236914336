import React from "react";
import { Row } from "react-bootstrap";
import "./Ubicacion.scss";

const Ubicacion = () => {
  return (
    <section className="sec_ubicacion">
      <Row className="row_ubicacion">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14162.79854221256!2d-58.9987419!3d-27.4474701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450d827c9dc1db%3A0x1f9562a4d55a7dee!2sBasa%20S.R.L.!5e0!3m2!1ses-419!2sar!4v1678888907890!5m2!1ses-419!2sar"
          width="1920"
          height="600"
          //   style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="ubication"
        ></iframe>
      </Row>
    </section>
  );
};

export default Ubicacion;
