// Libraries
import { Switch, Route, useLocation } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

// Components
import Home from "./components/Home/Home";
import Header from "./components/Layouts/Header/Header";
import Footer from "./components/Layouts/Footer/Footer";
import Contact from "./components/Layouts/Contact/Contact.jsx";
import WhatsAppButton from "./components/Layouts/WhatsAppButton/WhatsAppButton.jsx";
import Desarrollos from "./components/Desarrollos/Desarrollos.jsx";
import Prensa from "./components/Prensa/Prensa";
import Constructora from "./components/Constructora/Constructora";
import Ubicacion from "./components/Home/Ubicacion/Ubicacion";
import Profesionales from "./components/Profesionales/Profesionales";
import Noticia from "./components/Prensa/Noticia/Noticia";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { NotFound } from "./components/NotFound/NotFound";
import { useEffect } from "react";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <>
      <Header />
      <Switch location={location}>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/desarrollos/:category">
          <Desarrollos />
        </Route>

        <Route exact path="/constructora">
          <Constructora />
          <Contact />
          <Ubicacion />
        </Route>

        <Route path="/constructora/profesionales">
          <Constructora />
          <Profesionales />
          <Contact />
          <Ubicacion />
        </Route>

        <Route exact path="/prensa">
          <Prensa />
        </Route>

        <Route exact path="/prensa/:idNoticia">
          <Noticia />
        </Route>

        <Route component={NotFound} />
      </Switch>
      <Footer />
      <WhatsAppButton />
    </>
  );
};

export default App;
