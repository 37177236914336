import React from "react";
import { MdOutlineEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from "react";
import emailjs from "emailjs-com";
import "./Contact.scss";
import { Button, Col, Row } from "react-bootstrap";
import { useSnackbar } from "notistack";

const Contact = () => {
  const form = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_landing836",
        "template_m4hhljq",
        form.current,
        "SgINCuHL32YnG0s8A"
      )
      .then(
        (result) => {
          enqueueSnackbar("Mensaje enviado", { variant: "success" });
          console.log(result.text);
        },
        (error) => {
          enqueueSnackbar("No se pudo enviar el mensaje", { variant: "error" });
          console.log(error.text);
        }
      );

    e.target.reset();
  };
  return (
    <>
      <section id="contacto">
        <Row className="container contact__container">
          <Col md={6} className="contact__options">
            <article className="contact__option">
              <MdOutlineEmail className="contact__option-icon" />
              <h4>Email</h4>
              <h5>info@basadesarrollos.com</h5>
              <Button
                href="mailto:info@basadesarrollos.com"
                variant="outline-light"
                className="button-desc-plan"
              >
                Envía un email
              </Button>
            </article>
            <article className="contact__option">
              <BsWhatsapp className="contact__option-icon" />
              <h4>WhatsApp</h4>
              <h5>+54-3624-920002</h5>
              <Button
                href="https://web.whatsapp.com/send?phone=5493624920002&text="
                variant="outline-light"
                className="button-desc-plan"
              >
                Envía un mensaje
              </Button>
            </article>
          </Col>
          {/* END OF CONTACT OPTIONS */}
          <Col md={6}>
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="name"
                placeholder="Tu Nombre Completo"
                required
              />
              <input
                type="phone"
                name="phone"
                placeholder="Tu Teléfono"
                required
              />
              <textarea
                name="message"
                rows="7"
                placeholder="Tu Mensaje"
                required
              ></textarea>
              <button type="submit" className="btn btn-primary">
                Enviar
              </button>
            </form>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Contact;
