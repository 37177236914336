import React from "react";
import "./Hero.scss";
import { BTCarousel } from "../../../library/BTCarousel/BTCarousel";
import { heroHome } from "../../utils/heros";

const Hero = () => {
  return (
    <div className="sec-hero">
      <BTCarousel {...heroHome} />
    </div>
  );
};

export default Hero;
