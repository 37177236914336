import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import "./ElemProfesionales.scss";

const ElemProfesionales = ({ img, name, lastname, pos }) => {
  const myComponentRef = useRef(null);
  const [active, setActive] = useState(false);
  const [componentPosition, setComponentPosition] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      // const componentWidth = myComponentRef.current.offsetWidth;
      var componentPosition;
      if (componentPosition !== null) {
        componentPosition = myComponentRef.current.getBoundingClientRect().left;
      }

      if (componentPosition < windowWidth / 2) {
        setComponentPosition("left");
      } else {
        setComponentPosition("right");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Col
        xs={6}
        md={2}
        onClick={() => setActive(!active)}
        onMouseLeave={() => setActive(false)}
        className={`prof`}
        ref={myComponentRef}
      >
        <div className="prof__img d-block">
          <img src={img} alt="" width="100%" />
        </div>
        <div
          className={`prof__info ${
            componentPosition === "left" ? "--left" : "--right"
          } ${active ? "--active" : "--hidden"}`}
        >
          <h1 className="h1_name">{name}</h1>
          <h1 className="h1_lastname">{lastname}</h1>
          <h4 className="h4_pos">{pos}</h4>
        </div>
      </Col>
    </>
  );
};

export default ElemProfesionales;
