import React from "react";

import "./WhatsAppButton.scss";
import whatsIcon from "../../../assets/wasac.svg";

const WhatsAppButton = () => {
  return (
    <div className="btn-whatsapp">
      <a
        href="https://web.whatsapp.com/send?phone=5493624920002&text="
        target="_blank"
        rel="noreferrer"
      >
        <img src={whatsIcon} alt="" width="100%" />
        {/* <WhatsAppIcon sx={{ fontSize: 52 }} /> */}
      </a>
    </div>
  );
};

export default WhatsAppButton;
