import React from "react";
import { Col, Row } from "react-bootstrap";
import "./DesarrollosSec.scss";
import { desarrollos } from "../../utils/proyectos";
import ElementoDesarrollo from "../../Layouts/ElementoDesarrollo/ElementoDesarrollo";
import { Link } from "react-router-dom";

const DesarrollosSec = () => {
  // let details = navigator.userAgent;
  // let regexp = /android|iphone|kindle|ipad/i;
  // let isMobileDevice = regexp.test(details);

  return (
    <section className="sec-desarrollos">
      <Link to="/desarrollos/todos">
        <Row>
          <Col xs={6} className="col-espacio">
            <Col xs={12} md={6} className="col-desarrollos">
              <h2 className="h2-titulo">{"Desarrollos"}</h2>
            </Col>
          </Col>
          <Col className="col-espacio"></Col>
        </Row>
      </Link>
      <Row>
        {desarrollos.map((el, i) => (
          <Col xs={12} md={4}>
            <ElementoDesarrollo {...el} category={el.category_title} />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default DesarrollosSec;
