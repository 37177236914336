export const heroHome = {
  items: [
    {
      button: {
        variant: "outline-light",
        label: "Más Información",
        href: "https://torresmagistrados.com",
      },
      image: "https://i.imgur.com/P4u0B6v.jpg",
      imageMobile: "https://i.imgur.com/6YR0tx4.jpg",
    },
    {
      title: "Desarrollamos Confianza",
      image: "https://i.imgur.com/JgRizmZ.jpg",
      imageMobile: "https://i.imgur.com/jH9nPfv.jpg",
    },
    {
      title: "+20 Años de Trayectoria",
      image: "https://i.imgur.com/o2Nifnl.jpg",
      imageMobile: "https://i.imgur.com/r73G5Wj.jpg",
    },
    {
      title: "Desarrollamos Desde el Momento Cero",
      image: "https://i.imgur.com/HUgXYoj.jpg",
      imageMobile: "https://i.imgur.com/dGOS17a.jpg",
    },
    {
      title: "BASA Sello de Garantía",
      image: "https://i.imgur.com/hcECwdA.jpg",
      imageMobile: "https://i.imgur.com/V3AAcQL.jpg",
    },
  ],
  interval: 4500,
};

export const heroConstructora = {
  items: [
    {
      title: "+300 obras realizadas",
      subtitle:
        "Somos una empresa dedicada al desarrollo y construcción de proyectos\n inmobiliarios rentables, innovadores, eficientes y de calidad",
      image: "https://i.imgur.com/ZEGlDgv.jpg",
      imageMobile: "https://i.imgur.com/8AeHHEv.jpg",
    },
    {
      title: "20 años nos avalan",
      subtitle:
        "A lo largo de 20 años hemos realizado más de 300 obras \na lo largo y ancho del nordeste",
      image: "https://i.imgur.com/UpYoJEY.jpg",
      imageMobile: "https://i.imgur.com/eEzhkcm.jpg",
    },
    {
      title: "Equipo de profesionales",
      image: "https://i.imgur.com/pYcQ7Gi.jpg",
      imageMobile: "https://i.imgur.com/UBAIAlv.jpg",
      button: {
        label: "Conocenos",
        variant: "outline-light",
        to: "/constructora/profesionales",
      },
    },
    {
      title: "Proyectos sustentables",
      subtitle:
        "Nos comprometemos con la utilización de nuevas tecnologías que promuevan \n el cuidado al medio ambiente",
      image: "https://i.imgur.com/aC2vCfj.jpg",
      imageMobile: "https://i.imgur.com/2O27Fon.jpg",
    },
  ],
  interval: 4500,
};
