import React from "react";
import { Row } from "react-bootstrap";
import "./Profesionales.scss";
import { profesionales } from "../utils/profesionales";
import ElemProfesionales from "./ElemProfesionales/ElemProfesionales";

const Profesionales = () => {
  return (
    <>
      <Row className="row_profesionales">
        {profesionales.map((item, index) => (
          <ElemProfesionales
            img={item.img}
            name={item.name}
            lastname={item.lastname}
            pos={item.position}
          />
        ))}
      </Row>
    </>
  );
};

export default Profesionales;
