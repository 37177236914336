import React from "react";
import { Col, Row } from "react-bootstrap";
import "./NoticiaElement.scss";
import { Link } from "react-router-dom";

const NoticiaElement = ({ title, desc, img, last, id, href }) => {
  return (
    <Row className="row_container-noticia">
      {id === 5 || id === 6 || id === 7 ? (
        <a href={href} target="_blank" rel="noreferrer">
          <Row className="row_columns justify-content-md-center">
            <Col md={2} className="col_text">
              <h4>{title}</h4>
              <p>{desc}</p>
            </Col>
            <Col md={6} className="col_img">
              <img src={img} alt="noticia-imágen" className="img_noticia" />
            </Col>
          </Row>
        </a>
      ) : (
        <Link to={`/prensa/${id}`}>
          <Row className="row_columns justify-content-md-center">
            <Col md={2} className="col_text">
              <h4>{title}</h4>
              <p>{desc}</p>
            </Col>
            <Col md={6} className="col_img">
              <img src={img} alt="noticia-imágen" className="img_noticia" />
            </Col>
          </Row>
        </Link>
      )}
      <Row className="justify-content-md-center">
        <div
          className={`${last ? "div_separador" : "div_separador_grey"}`}
        ></div>
      </Row>
    </Row>
  );
};

export default NoticiaElement;
